<template>
      <section>
            <div class="order-container grid lg:grid-flow-row md:grid-flow-row lg:grid-cols-4 md:grid-cols-4 grid-flow-row-dense overflow-x-auto">
                  <div class="order-container-wrapper desktop"><strong>Bestelldatum</strong></div>
                  <div class="order-container-wrapper desktop"><strong>Bestellnummer</strong></div>
                  <div class="order-container-wrapper desktop"><strong>Bestellstatus</strong></div>
                  <div></div>
            </div>
            <div v-if="orders.items">
                  <div class="order-container grid lg:grid-flow-row md:grid-flow-row lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2 xxs:grid-cols-1 grid-flow-row-dense xxs:grid-flow-row xxs:auto-cols-max overflow-y-auto" v-for="order in orders.items" :key="order.id">
                        <div class="order-container-wrapper">
                              <div class="mobile"><strong>Bestelldatum</strong></div>
                              <div>{{ dateCreatedAt(order) }}</div>
                        </div>
                        <div class="order-container-wrapper">
                              <div class="mobile"><strong>Bestellnummer</strong></div>
                              <div>{{ order.order_id }}</div>
                        </div>
                        <div class="order-container-wrapper">
                              <div class="mobile"><strong>Bestellstatus</strong></div>
                              <div>{{ order.current_order_status_text_web }}</div>
                        </div>
                        <div class="order-container-wrapper">
                              <span class="text-blue" @click="displayDetails(order)">Details</span>
                        </div>

                        <div :class="order.order_id == active ? 'show' : 'hide'" class="col-span-4 order-container-wrapper">
                              <div class="bg-blue-lightest p-4">
                                    <div class="pl-2 grid lg:grid-flow-row md:grid-flow-row lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 xxs:grid-cols-2 grid-flow-row-dense xxs:grid-flow-row xxs:auto-cols-max">
                                          <div class="addresses" v-for="address in order.addresses" :key="address.address_type">
                                                <div class="py-2 pb-2 font-bold" v-if="address.address_type == 'billing'">Rechnungsadresse</div>
                                                <div class="py-2 pb-2 font-bold" v-else>Lieferadresse</div>
                                                <div>{{ address.firstname + " " + address.lastname }}</div>
                                                <div>{{ address.street + " " + address.streetNo }}</div>
                                                <div>{{ address.additional_street }}</div>
                                                <br />

                                                <div>{{ address.postcode + " " + address.city }}</div>
                                                <div>{{ fetchCountryNameByCode(address.country_id) }}</div>
                                          </div>
                                    </div>
                                    <div class="py-2 pb-2"></div>
                                    <div class="px-2">
                                          <div><strong>Email-Adresse:</strong></div>
                                          <div>{{ order.confirm_email }}</div>
                                    </div>
                                    <div class="py-2 pb-2"></div>
                                    <div class="px-2 lg:w-2/4 sm:w-full md:1-2/4 xs:w-full">Bitte beachten Sie: Ihr digitaler Gutscheincode wird an oben genannte eMail Adresse gesendet.</div>
                              </div>
                              <!-- Item information -->
                              <div class="p-4 flex" v-for="item in order.items" :key="item.id">
                                    <div class="w-full justify-between">
                                          <div class="justify-start float-left">
<!--                                                <a :href="`produkt/${item.product_sku}`">-->
<!--                                                      <h3>{{ item.qty }} x {{ item.name }}</h3>-->
<!--                                                </a>-->
                                            <router-link :to="{
                                                        name: 'product-details',
                                                        params: { seo_url: item.product_sku }
                                                  }">
                                              <h3>{{ item.qty }} x {{ item.name }}</h3>
                                            </router-link>
                                                <p class="flex" v-if="item.product_group !== 'payback_article' && Object.keys(item.trackingInformation).length > 0">
                                                      {{ item.trackingInformation?.companyLabel }} Tracking Nr.:
                                                      <div v-if="item?.trackingInformation?.trackingNumber && item.trackingInformation?.delivery_url">
                                                            <a  target="_blank" rel="noopener" :title="item.trackingInformation.trackingNumber" :href="item.trackingInformation.delivery_url + item.trackingInformation.trackingNumber">{{ item.trackingInformation.trackingNumber }}</a>
                                                      </div>
                                                <div v-else>
                                                      <a class="cursor-default" rel="noopener" :title="item.trackingInformation.trackingNumber">{{ item?.trackingInformation?.trackingNumber }}</a>
                                                </div>
                                                      
                                                </p>
                                          </div>
                                          <div class="justify-end float-right">
                                                <div class="container-for-element block">
                                                      <p>{{ item.current_order_status_text_web }}</p>
                                                </div>

                                                <div class="container-for-element text-right block">
                                                      <h3>{{ item.total }} °P</h3>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <!-- Cart Information -->
                              <div class="bg-blue-lightest p-4">
                                    <div class="container-for-element">
                                          <div class="flex justify-between">
                                                <div>
                                                      <div>Zwischensumme</div>
                                                </div>
                                                <div v-if="order?.grand_total_without_discounts">
                                                      <div>{{ order.grand_total_without_discounts }} °P</div>
                                                </div>
                                          </div>
                                          <div class="flex justify-between">
                                                <div>
                                                      <div>Gutschein</div>
                                                </div>
                                                <div v-if="order?.discounts">
                                                      <div>- {{ order?.discounts[1] ? order.discounts[1]?.value : 0 }} °P</div>
                                                </div>
                                          </div>
                                          <div class="flex justify-between">
                                                <div>
                                                      <div>Versandkosten</div>
                                                </div>
                                                <div>
                                                      <div>0 °P</div>
                                                </div>
                                          </div>
                                    </div>
                                    <hr />
                                    <div class="container-for-element">
                                          <div class="flex justify-between">
                                                <div>
                                                      <div>
                                                            <strong>Endsumme</strong>
                                                      </div>
                                                </div>
                                                <div v-if="order?.grand_total_with_discounts">
                                                      <div>
                                                            <strong>{{ order?.grand_total_with_discounts }} °P</strong>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="flex justify-between">
                                                <div>
                                                      <div>{{ t("LBL_POINTS_TO_REDEEM") }}</div>
                                                </div>
                                                <div  v-if="order?.discounts">
                                                      <div>{{ order?.discounts[0] ? order?.discounts[0].value : 0 }} °P</div>
                                                </div>
                                          </div>
                                          <div class="flex justify-between">
                                                <div>
                                                      <div>Zuzahlung</div>
                                                </div>
                                                <div>
                                                      <div>{{ order?.additional_payment ? order?.additional_payment : 0 }} €</div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="lg:col-span-4 md:col-span-4 sm:col-span-2 xs:col-span-2 border-b border-dotted border-gray-light"></div>
                  </div>
            </div>
      </section>
</template>

<script>
      import { useStore } from "vuex";
      import { computed, ref } from "vue";
      import { useI18n } from "vue-i18n";

      export default {
            props: {},
            name: "List",
            components: {},
             setup() {
                  const store = useStore();
                  const active = ref(null);
                  const { t } = useI18n();

                  const isStarting = computed(() => {
                    return  store.getters["auth/isStarting"]
                  });
                  const isLoggedIn = computed(() => {
                    return store.getters["account/isLoggedIn"];
                  });
                  if(isStarting.value === true && isLoggedIn.value === true) {
                     store.dispatch("order/getList");
                  }
                  const orders = computed(() => {
                    if (isLoggedIn.value === true) {
                      return store.getters["order/getList"];
                    }
                    else {
                      return [];
                    }
                  });
              const displayDetails = (order) => {
                        if (active.value == order.order_id) {
                              active.value = null;
                        } else {
                              active.value = order.order_id;
                        }
                        return {
                              display: "block"
                        };
                  };
                  const fetchCountryNameByCode = (countryCode) => {
                    if (Intl === void 0 || typeof Intl.DisplayNames !== 'function') {
                      return countryCode
                    }
                    const regionNames = new Intl.DisplayNames(["de"], { type: "region" });
                    return regionNames.of(countryCode);
                  };

                  const dateCreatedAt = (order) => {

                    return order.created_at.split(' ')[0]
                  }
                  return {
                        orders,
                        active,
                        displayDetails,
                        fetchCountryNameByCode,
                        dateCreatedAt,
                        t
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .order-container-wrapper {
            @apply text-black  px-1 py-2 rounded-md m-2;
            cursor: pointer;
            padding: 3px;
            border: none;
      }
      @media (max-width: 992px) {
            grid-column: 2/-1;
      }
      @media (max-width: 600px) {
            grid-column: 1/-1;
      }

      .show {
            display: block;
      }
      .hide {
            display: none;
      }
      .desktop {
            display: block;
      }
      .mobile {
            display: none;
      }
      @media (max-width: 960px) {
            .order-container-wrapper {
                  grid-column: 1/-1;
            }
            .addresses {
                  grid-column: 1/-1;
            }
            .mobile {
                  display: block;
            }
            .desktop {
                  display: none;
            }
      }
</style>
