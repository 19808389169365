<template>
      <section>
            <nav-menu></nav-menu>
            <div class="page-content-lightestblue">
                  <div class="page-content-lightestblue__container">
                        <app-image :alt="order - list" :src="require('../assets/img/Meine_Praemienbestellungen_1536x360.png')"></app-image>
                        <h2>{{ t("LBL_MY_ORDERS") }}</h2>
                        <List></List>
                  </div>
            </div>
      </section>
</template>

<script>
      import List from "@/components/Order/List";
      import NavMenu from "@/components/Menu/NavMenu";
      import AppImage from "@/components/Image/Image";
      import { cookieService } from "@/service/cookieConsentService";
      import tService from "@/service/tService";

      import { useI18n } from "vue-i18n";

      export default {
            name: "OrdersList",
            components: {
                  List,
                  NavMenu,
                  AppImage
            },
            setup() {
                  const { t } = useI18n();
                  // Enable tracking only if statistics group is enabled
                  if (cookieService.isStatisticsGroupEnabled()) {
                        const trackingService = new tService();
                        trackingService.init();
                        trackingService.track();
                  }
                  return { t };
            }
      };
</script>
<style scoped lang="postcss">
      .page-content-lightestblue {
            @apply bg-blue-lighter;
      }
</style>
